@import "../../../../assets/scss/variable.scss";

.ranking-landing-page-wrap {
  @media (max-width: 659px) {
    margin-top: 12px;
  }

  .ranking-landing-header {
    margin-bottom: 18px;
    border-bottom: 1px solid $color-grey;

    @media (max-width: 659px) {
      display: block;
    }
  }

  .ranking-team-select-wrap {
    display: flex;
    align-items: center;
    column-gap: 9px;

    @media (max-width: 659px) {
      flex-wrap: wrap;
      row-gap: 12px;
    }

    .league-table-select {
      min-width: 260px;

      @media (max-width: 1023px) {
        min-width: 180px;
        max-width: 180px;
      }

      @media (max-width: 659px) {
        width: 100%;
        min-width: auto;
        max-width: none;
      }
    }

    .season-table-select {
      min-width: 260px;

      @media (max-width: 1023px) {
        min-width: 180px;
        max-width: 180px;
      }

      @media (max-width: 659px) {
        width: calc(50% - 4.5px);
        min-width: auto;
        max-width: none;
      }
    }

    .stats-select {
      @media (max-width: 659px) {
        width: calc(50% - 4.5px);
        min-width: auto;
        max-width: none;
      }
    }

    .table-select {
      .select__control {
        @media (max-width: 659px) {
          max-width: none;
        }
      }
    }
  }
}

.stats-section-wrap {
  background-color: $color-White;
  box-shadow: 0px 3px 9px 0px #0000000d;
  border-radius: 8px;

  .stats-header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 18px;

    @media (max-width: 659px) {
      flex-direction: column;
      row-gap: 12px;
      align-items: flex-start;
    }

    .stats-name {
      font-size: 22.4px;
      line-height: 27px;
      font-weight: 600;
      font-family: $regulerFont;
      letter-spacing: 0px;
      color: $color-Black;

      @media (max-width: 659px) {
        font-size: 16px;
        line-height: 19px;
      }
    }

    .stats-table-select {
      min-width: 260px;

      @media (max-width: 659px) {
        min-width: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;

        .customize-button {
          width: 100%;
          padding: 0px;
        }
        .css-wxzyoj-MuiButtonBase-root-MuiButton-root {
          width: 100%;
        }
        .select__control {
          max-width: none;
        }
      }
    }
  }

  .show-more-cell {
    background-color: $color-Light-grey;

    .show-more-text {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
      font-size: 16px;
      line-height: 19px;
      font-weight: 500;
      font-family: $regulerFont;
      color: $color-Accent-1;
      letter-spacing: 0px;
    }
  }
}
